body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #efeff0 !important; */
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customScroll::-webkit-scrollbar {
  width: 20px;
  height: 10px;
}

.customScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
/* added new class */
.alignImg.css-o69gx8-MuiCardMedia-root {    
	width: unset;
	margin: auto;
}
.customPadding.css-1056no7-MuiGrid2-root {
  padding: 20px 40px
}
.removePadding .css-ppkxzd-MuiGrid2-root{
  --Grid-columnSpacing: 0px
}
.easter-egg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.easter-egg {
  perspective: 1000px;
  width: 200px;
  height: 200px;
  cursor: pointer;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.easter-egg img {
  width: 100%;
  height: 100%;
}

.easter-egg.show-egg {
  animation: flip 3s linear;
}

@keyframes flip {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  50% {
    transform: perspective(1000px) rotateY(180deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.alignBtn.MuiButton-containedPrimary{ margin: 2px 5px;}
.datePicker_block div:first-child{padding: 0;}
/* .datePicker_block div:first-child{padding: unset;} */
.applyStyle1{
  width: 80%;
   max-width: 500px;
   margin: 5px auto;
}
.applyStyle2{
  width: 80%;
   max-width: 500px;
   margin: 5px auto;
}
.applyStyle1 .css-1xhypcz-MuiStack-root>.MuiTextField-root, .applyStyle2 .css-1xhypcz-MuiStack-root>.MuiTextField-root {  min-width: 100%;}

.applyStyle1 .css-1xhypcz-MuiStack-root{  padding-top: 0;}
.applyStyle2 .css-1xhypcz-MuiStack-root{  padding-top: 5px;}